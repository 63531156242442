<template>
  <div>
    <el-page-header icon="el-icon-arrow-left" title="" @back="goBack" :content="chapter_title" class="header"></el-page-header>
    <div class="box">
        <div v-if="(courseWareList.length > 0 || course.question_num > 0) && !isShowSmWindow"
             :class="{'box_left': true, 'not-select': dragParams.isDrag}"
             :style="{width: dragParams.disX >= 400 ? dragParams.disX + 'px' : '400px'}">
          <div class="window_btn" @click="openWindow"><i class="iconfont icon-fangda"></i></div>
          <el-tabs v-model="activeName" class="top_tabs" v-if="courseWareList.length > 0 || course.question_num > 0">
            <el-tab-pane label="课件" name="courseware" v-if="courseWareList.length > 0 ? true : false">
              <TeachingCourseware :selected_index="coursewareSelected" :courseware_list="courseWareList" @getCourseWareSelectedIndex="getCourseWareSelectedIndex"></TeachingCourseware>
            </el-tab-pane>
            <el-tab-pane label="练习题" name="question" v-if="course.question_num > 0">
              <TeachingExercise :paper_ids="course.paper_ids" :cur_name="cur_name" @getAnswers="getAnswers" @getSubmit="getSubmit"></TeachingExercise>
            </el-tab-pane>
          </el-tabs>
<!--          <div class="bottom">-->
<!--            <div class="chapter_wrap">-->
<!--              <el-button type="primary" icon="el-icon-menu" size="small" @click="chapter.visible = !chapter.visible" @click.stop>课程章节</el-button>-->
<!--              <div class="chapter" v-show="chapter.visible" @click.stop>-->
<!--                <dl>-->
<!--                  <div v-for="(chapter_item, chapter_index) in chapter.list">-->
<!--                    <dt v-if="chapter.list.length > 1">{{ chapter_item.title }}</dt>-->
<!--                    <dd @click="goPath(`/course/detail/${item.type}/${cur_uuid}/${item.uuid}`)" :class="{'active': item.video_uuid == uuid || item.live_uuid == uuid || item.face_uuid == uuid || item.authentication_uuid == uuid}" v-for="(item, index) in chapter_item.chapter_content">-->
<!--                      <div class="left">-->
<!--                        <i :class="{'iconfont': true, 'icon-qqq-02': item.live_uuid, 'icon-qqq_huaban1': item.face_uuid,-->
<!--                          'icon-qqq-03': item.video_uuid, 'icon-tubiaocankaoxian1024-05': item.authentication_uuid}"></i>-->
<!--                        <span class="title text_ellipsis">{{item.name}}</span>-->
<!--                      </div>-->
<!--                      <div class="right">-->
<!--                        <span>{{ item.state }}</span>-->
<!--&lt;!&ndash;                        <span v-if="item.video_uuid == uuid || item.live_uuid == uuid || item.face_uuid == uuid || item.authentication_uuid == uuid">学习中</span>&ndash;&gt;-->
<!--&lt;!&ndash;                        <span v-else-if="item.video_uuid && item.video.over">已结束</span>&ndash;&gt;-->
<!--                      </div>-->
<!--                    </dd>-->
<!--                  </div>-->
<!--                </dl>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
          <div class="layer" v-show="layerShow"></div>
        </div>
        <div class="box_line" v-if="courseWareList.length > 0 || course.question_num > 0" @mousedown="dragMouseDown($event)" :style="{left: dragParams.disX + 'px'}">
          <span>
            <i class="el-icon-d-caret"></i>
          </span>
        </div>
        <div :class="{'box_right': true, 'not-select': dragParams.isDrag}"
             :style="{left: (courseWareList.length > 0 || course.question_num > 0)  && !isShowSmWindow ? dragParams.disX + dragParams.lineWidth + 'px' : 0}">
          <div class="content_wrap">
            <!-- 认证 -->
            <div class="info_center" v-if="type == 'authentication'">
              <h2>{{ chapter_title }}</h2>
              <p>{{ course.platform }}</p>
              <p>{{ course.description }}</p>
              <p>授权码：{{ course.assign_state }}</p>
              <p>到期日期：<span v-if="course.assign_state == '发放中'">-</span><span v-else>{{ course.code.expired_at}}</span></p>
            </div>
            <!-- 面授 -->
            <div class="info_center" v-if="type == 'face_teaching'">
              <h2>{{ chapter_title }}</h2>
              <p>{{ course.description }}</p>
              <p><i class="el-icon-timer"></i> {{ course.start_at }}</p>
              <p><i class="el-icon-map-location"></i> {{ course.position_str }}</p>
            </div>
            <!-- 视频 -->
            <div class="video_wrap" v-if="type == 'video'" ref="videoWrap">
              <div class="info" style="position: relative">
                <h4>{{ chapter_title }}</h4>
                <p>{{ course.description }}</p>
              </div>
              <div style="display: flex; flex: 1;">
                <video-play v-if="canStudy" :canStudy="canStudy" :video="course" :record="true" :cur_uuid="cur_uuid" ref="video"></video-play>
              </div>
            </div>
            <!-- 直播 -->
            <div class="info_center" v-if="type == 'live' && !showPlayback">
              <h2>{{ chapter_title }}</h2>
              <p>{{ course.description }}</p>
              <p><i class="el-icon-timer"></i>{{showTime(course.start_at, course.end_at)}}</p>
              <div v-if="getLiveStatus(course.end_at) == 1">
                <p class="text-center"><el-button type="primary" @click="enterLive">进入直播</el-button></p>
              </div>
              <div v-else-if="course.playback_videos && course.playback_videos.length > 0">
                <p class="text-center"><el-button type="primary" @click="showPlayback = true">直播回放</el-button></p>
              </div>
              <div v-else>
                <p class="text-center"><span class="waiting">回放视频处理中</span></p>
              </div>
            </div>
            <!-- 进入直播 -->
            <div class="video_wrap" v-if="type == 'live' && showPlayback">
              <div class="info">
                <el-select @change="playbackVideoSelectChanged" v-model="live.value" placeholder="请选择" class="live_select">
                  <el-option
                    v-for="item in live.options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
                <p>{{live.playback_description}}</p>
                <el-button type="primary" size="small" class="back_btn" @click="showPlayback = false">返回详情</el-button>
              </div>
               <div style="height: 100%">
                <video-play v-if="canStudy" :canStudy="canStudy" :video="live.playback_video" :record="false" :cur_uuid="cur_uuid" ref="live"></video-play>
              </div>
              <!-- iframe视频 -->
            </div>
          </div>
          <div class="layer" v-show="layerShow"></div>
        </div>
    </div>
    <!-- 章节列表 -->
    <chapter-list v-if="showChapter"></chapter-list>
  </div>
</template>

<script>
import TeachingCourseware from 'views_f/course/includes/TeachingCourseware'
import TeachingExercise from 'views_f/course/includes/TeachingExercise'
import ChapterList from 'components/course/ChapterList'
import { init, getCurRecord, checkLogin, getCurChapter } from "api/course/course/study"
import { listVideo } from "api/course/video/list"
import VideoPlay from "components/course/VideoPlay"
import { checkUserStudy } from "api/course/course/study"

export default {
  components: {
    TeachingCourseware,
    TeachingExercise,
    VideoPlay,
    ChapterList
  },
  data() {
    return {
      // videoHeight: '0',
      type: '',
      cur_uuid: '',
      cur_name: '',
      uuid: '',
      chapter_title: '',
      layerShow: false,
      courseWareList: [],
      course: {},
      canStudy: false,
      dragParams: {
        isDrag: false,
        initDisX: 400,
        disX: 400,
        lineWidth: 12,
      },
      activeName: 'courseware',
      // chapter: {
      //   visible: false,
      //   list: []
      // },
      live: {
        value: '0',
        playback_video: '',
        playback_description: '',
        options: []
      },
      showPlayback: false,
      showChapter: true,
      newWindow: '',
      interval: '',
      recordData: {
        code: 0,
        isSubmit: false,
        data: {} //只传全部中的数据，不传错题集
      },
      recordCoursewareData: {
        code: 1,
        selected: ''
      },
      coursewareSelected: 0,
      isShowSmWindow: false,
      saveAnswer: ''
    }
  },
  created() {
    window.addEventListener('resize', this.resizeDisX);
    window.addEventListener('beforeunload', this.closeSmWindow)
    this.clearSession()
  },
  mounted() {
    document.getElementsByTagName('body')[0].className = 'teaching_page';

    this.type = this.$route.params.type
    this.cur_uuid = this.$route.params.cur_uuid
    this.uuid = this.$route.params.uuid
    this.initPage()
  },
  beforeUnmount() {
    window.removeEventListener('message', this.messageHandler)
    window.removeEventListener('beforeunload', this.closeSmWindow)
    window.removeEventListener('resize', this.resizeDisX)
    this.closeSmWindow()
    // this.clearSession()
  },
  unmounted () {
    document.body.removeAttribute('class')
  },
  methods: {
    getCourseWareSelectedIndex(val) {
      console.log('获取课件选择值')
      console.log(val);

      this.recordCoursewareData.selected = val
    },
    resizeDisX() {
      this.dragParams.initDisX = 400;
      this.dragParams.disX = 400;
    },
    closeSmWindow() {
      if(this.newWindow) {
        this.newWindow.close()
      }
    },
    initPage(){
      setInterval(checkLogin, 600000)
      const that = this
      if(this.uuid == undefined || this.uuid == '' || this.uuid == 'undefined'){
        return false;
      }
      let params = {'type': this.type, 'uuid': this.uuid, 'cur_uuid': this.cur_uuid}

      init(params).then(res => {
        if(res.code === 0){
          let data = res.data
          that.cur_name = res.cur_name
          that.course = data

          if(res.courseware_list.length > 0){
            that.activeName = 'courseware'
          }
          else {
            that.activeName = 'question'
          }
          that.courseWareList = res.courseware_list
          if(that.type == 'live'){
            let playback_videos = data.playback_videos
            if (playback_videos.length > 0){
              let video_id = playback_videos[0].id
              that.live.value = video_id
              listVideo({'video_id': video_id})
                .then(v => {
                  if(v.total > 0){
                    that.live.playback_video = v.data[0]
                    that.live.playback_description = v.data[0].description
                  }
                  else {
                    that.$alert('获取视频失败', '提示', {
                      confirmButtonText: '确定',
                    });
                  }
                })
            }
            playback_videos.forEach(v => {
              that.live.options.push({
                'value': v.id,
                'label': v.name
              })
            })
          }
          that.canStudy = true
          that.setChapterState()
        }
        else if(res.code == 1){
          this.goPath('/course/detail/' + this.cur_uuid)
        }
        else if(res.code == 2){
          this.goPath('/login?target=' + location.pathname)
        }
        else {
          that.$alert(res.error, '提示', {
            confirmButtonText: '确定',
            callback: action => {
              that.goPath('/course/detail/' + that.cur_uuid)
            }
          });
        }
      })
    },
    getLiveStatus(end_at){
      if(end_at != undefined){
        let end = dayjs().diff(end_at)
        if(end < 0){
          return 1
        }
      }
    },
    enterLive(){
      window.open(this.course.join_url)
    },
    playbackVideoSelectChanged(video_id){
      let that = this
      listVideo({'video_id': video_id})
            .then(v => {
              if(v.total > 0){
                that.$refs.live.destoryVideoPlayer()
                that.live.playback_video = v.data[0]
                that.live.playback_description = v.data[0].description
              }
              else {
                that.$alert('获取视频失败', '提示', {
                  confirmButtonText: '确定'
                });
              }
            })
    },
    showTime(start_at, end_at){
      let t = dayjs(end_at).unix() - dayjs(start_at).unix()
      let startDate = dayjs(start_at).format('YYYY-MM-DD')
      let endDate = dayjs(end_at).format('YYYY-MM-DD')
      if (startDate == endDate){
        return startDate + ' ' + dayjs(start_at).format('HH:mm:ss') + '-' + dayjs(end_at).format('HH:mm:ss')
      }else{
        return dayjs(start_at).format('YYYY-MM-DD HH:mm:ss') + '-' + dayjs(end_at).format('YYYY-MM-DD HH:mm:ss')
      }

    },
    goBack() {
      this.saveAnswer = ''
      this.$router.push(`/course/detail/${this.cur_uuid}`);
    },
    dragMouseDown(event) {
      this.dragParams.isDrag = true;
      this.layerShow = true;
      const initClientX = event.clientX;
      document.onmousemove = (event) => {
        if(this.dragParams.isDrag) {
          let newDisX = this.dragParams.initDisX + (event.clientX - initClientX);
          if(newDisX < 0) newDisX = 0;
          if(newDisX > document.body.clientWidth - this.dragParams.lineWidth) newDisX = document.body.clientWidth - this.dragParams.lineWidth;
          this.dragParams.disX = newDisX;
        }
      }
      document.onmouseup = () => {
        this.dragParams.isDrag = false;
        document.onmousemove = null;
        document.onmouseup = null;

        this.dragParams.initDisX = this.dragParams.disX;
        this.layerShow = false;
      }
    },
    // closeChapter() {
    //   this.chapter.visible = false;
    // },
    goPath(path) {
      this.$router.push(path);
    },
    setChapterState() {
      let params = {'uuid': this.uuid, 'cur_uuid': this.cur_uuid}
      const that = this
      getCurChapter(params).then(res => {
        if(res.code == 0){
          that.chapter_title = res.chapter_title
          // that.chapter.list = res.chapter

          //标签页title
          document.title = `${ that.chapter_title }-${ that.cur_name }-未来网络学堂`;

          // let video_index = 0
          // let now = new Date()
          // for(let i=0; i<this.chapter.list.length; i++) {
          //   this.chapter.list[i].chapter_content.forEach(item => {
          //     if (item.face_uuid) {
          //       item.start_at = dayjs(item.face_teaching.start_at).format('MM-DD HH:mm')
          //       item.end_at = dayjs(item.face_teaching.end_at).format('MM-DD HH:mm')
          //       let position = JSON.parse(item.face_teaching.position)
          //       if (position[0].label.indexOf('市') >= 0) {
          //         item.city = position[0].label
          //       } else {
          //         item.city = position[1].label
          //       }
          //       if (now < new Date(item.face_teaching.start_at)) {
          //         item.state = '未开始'
          //       } else if (now < new Date(item.face_teaching.end_at)) {
          //         item.state = '进行中'
          //       } else {
          //         item.state = '已结束'
          //       }
          //     } else if (item.live_uuid) {
          //       item.start_at = dayjs(item.live.start_at).format('MM-DD HH:mm')
          //       item.end_at = dayjs(item.live.end_at).format('MM-DD HH:mm')
          //       if (now < new Date(item.live.start_at)) {
          //         item.state = '未开始'
          //       } else if (now < new Date(item.live.end_at)) {
          //         item.state = '进行中'
          //       } else {
          //         item.state = '已结束'
          //       }
          //     } else if (item.video_uuid) {
          //       item.video_index = video_index++
          //     }
          //   })
          // }
          // this.getStudyState()
        }
      })
    },
    // getStudyState() {
    //   let data = {
    //     cur_uuid: this.cur_uuid
    //   }
    //   const that = this
    //   getCurRecord(data).then(res => {
    //     if(res.code === 0){
    //       let video_obj = {}
    //       let codes = []
    //       for(let i=0; i<res.video_records.length; i++){
    //         let record = res.video_records[i]
    //         if(record.is_finish || !(record.video_uuid in video_obj)){
    //           video_obj[record.video_uuid] = record.is_finish
    //         }
    //       }
    //       for(let i=0; i<res.codes.length; i++){
    //         codes.push(res.codes[i].auth_uuid)
    //       }
    //       that.chapter.list.forEach(chapter => {
    //         chapter.chapter_content.forEach(item => {
    //           if(item.video_uuid){
    //             if (item['video_uuid'] in video_obj){
    //               item.state = video_obj[item['video_uuid']] == 1 ? '已学习' : '学习中'
    //             }
    //             else {
    //               item.state = '未学习'
    //             }
    //           }
    //           if(item.authentication_uuid){
    //             if(codes.indexOf(item.authentication_uuid) >= 0){
    //               item.state = '已获取'
    //             }
    //             else {
    //               item.state = '发放中'
    //             }
    //           }
    //         })
    //       })
    //     }
    //   })
    // },
    openWindow() {
      const that = this;
      this.isShowSmWindow = true;
      this.newWindow = window.open(`/course/detail/content/${ this.$route.params.type }/${ this.$route.params.cur_uuid }/${ this.$route.params.uuid }`, '_blank', 'width=500,height=500,menubar=no,toolbar=no,location=no,directories=no,status=no,scrollbars=no,resizable=yes')
      console.log('openWindow')

      this.clearSession()

      window.addEventListener('message', this.messageHandler)
      this.interval = setInterval(()=>{
        if(this.newWindow && this.newWindow.closed) {
          console.log('son closed!!!')
          window.removeEventListener('message',this.messageHandler)
          if(this.saveAnswer !== '') {
            sessionStorage.setItem('answers', this.saveAnswer)
          }
          this.isShowSmWindow = false
          clearInterval(this.interval)
        }
      })
    },
    messageHandler(e) {
      if(e.data === 'opened') { //子窗口第一次加载完毕
        console.log('send to son')
        console.log(this.recordData.isSubmit);

        if (!this.recordData.isSubmit) {  //未提交，需传递给子窗口的数据
          console.log('====向子窗口发送试题数据====')
          this.newWindow.postMessage(JSON.parse(JSON.stringify(this.recordData)), '*')
        }
        this.newWindow.postMessage(JSON.parse(JSON.stringify(this.recordCoursewareData)), '*')
      } else if(e.data.code === 0) {  //子窗口传来的试题数据
          console.log('====子窗口关闭后返回数据====');
          this.saveAnswer = JSON.stringify(e.data.data) //临时存储，只有关闭小窗口才真正存储
      } else if(e.data.code === 1) {  //子窗口传来的课件数据
        console.log('===课件===');
        console.log(e.data)

        this.coursewareSelected = e.data.selected
      } else if(e.data === 'noDataSend') {  //子窗口无数据传递

        console.log('noDataSend');
        this.saveAnswer = ''
      }
    },
    getAnswers(data) {
      this.recordData.data = data
    },
    getSubmit(data) {
      //console.log('==============================我是父窗口的getSubmit');
      console.log(data)
      this.recordData.isSubmit = data
    },
    clearSession() {
      if(sessionStorage.getItem('answers')) {
        sessionStorage.removeItem('answers')
      }
    }
  },
  watch: {
    $route (val) {
      this.$router.go(0)
      // if(val.name === 'CourseTeaching'){
      //   // this.closeChapter()
      //   this.canStudy = false
      //   this.type = val.params.type
      //   this.cur_uuid = val.params.cur_uuid
      //   this.uuid = val.params.uuid
      //   this.showPlayback = false
      //   this.initPage()
      // }
      // this.showChapter = false
      // this.$nextTick(()=>{
      //   this.showChapter = true
      // })
    }
  }
}
</script>

<style lang="scss">
  .teaching_page {
    #chatBtn {
      display:none !important;
    }
  }
</style>

<style lang="scss" scoped>
  :deep(.header) {
    height:60px;
    line-height:60px;
    background:#009dd9;
    color:#fff;
    .el-page-header__left {
      background:#1aa7dd;
      margin-right:10px;
      .el-page-header__icon {
        width: 60px;
        justify-content: center;
      }
      &:after {
        display:none;
      }
      &:hover {
        background:#33B1E1;
      }
    }
    .el-page-header__content {
      color:#fff;
      font-size:16px;
    }
  }
  .box {
    position:absolute;
    top:60px;
    left:0;
    right:0;
    bottom:0;
    overflow:hidden;
    min-width:1280px;
    .box_left {
      width:400px;
      position:absolute;
      top:0;
      left:0;
      bottom:0;
      background:#fff;
      min-width: 400px;
      word-break: break-all;
      .window_btn {
        position:absolute;
        top:13px;
        right:15px;
        cursor: pointer;
        z-index:1;
        > i {
          color:#999;
          font-size:21px;
        }
        &:hover {
          > i {
            color:#666;
          }
        }
      }
      :deep(.top_tabs) {
        .el-tabs__header {
          margin-bottom:0;
          .el-tabs__nav-wrap {
            padding-left:15px;
            padding-right: 15px;
            .el-tabs__item {
              height:50px;
              line-height:50px;
            }
            &:after {
              height:1px;
            }
          }
        }
        .el-tabs__content {
          position: absolute;
          top: 50px;
          bottom: 0;
          right: 0;
          left: 0;
          overflow-y: auto;
        }
      }
      .bottom {
        height:60px;
        background:#f5f7fa;
        border-top:1px solid #E4E7ED;
        position:absolute;
        bottom:0;
        left:0;
        right:0;
        display: flex;
        align-items: center;
        .chapter_wrap {
          position:relative;
          margin-right:15px;
          margin-left:auto;
          .chapter {
            padding:20px;
            position:absolute;
            bottom:140%;
            left: 0;
            background:#fff;
            width:500px;
            z-index:11;
            box-shadow: 0 0 5px rgba(0,0,0,0.1);
            max-height: 500px;
            overflow-y: auto;
            overflow-x: hidden;
            dl {
              margin:0;
              dt {
                padding:10px;
                background:#f5f5f5;
                color:#333;
              }
              dd {
                margin:0;
                padding:10px;
                display:flex;
                font-size:12px;
                cursor: pointer;
                .left {
                  flex:1;
                  display: flex;
                  align-items: center;
                  overflow:hidden;
                  > i {
                    color:#9da6b7;
                    font-size:18px;
                    margin-right:10px;
                  }
                  > .title {
                    flex:1;
                    min-width: auto;
                    padding-top: 4px;
                    padding-bottom: 4px;
                  }
                }
                .right {
                  width:60px;
                  color:#999;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
                &:hover {
                  background:#f2fbff;
                }
                &.active {
                  .left {
                    > i, > .title {
                      color:#009dd9;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .box_line {
      position:absolute;
      top:0;
      bottom:0;
      left:400px;
      width:12px;
      background:#eee;
      cursor: e-resize;
      z-index:10;
      > span {
        background:#009dd9;
        display:block;
        padding-top:50px;
        padding-bottom:50px;
        color:#fff;
        text-align: center;
        overflow:hidden;
        position:absolute;
        top:50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        > i {
          font-size:12px;
          -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
        }
      }
    }
    .box_right {
      position:absolute;
      top:0;
      right:0;
      bottom:0;
      left:415px;
      background:#fff;
      min-width: 400px;
      word-break: break-all;
      z-index:10;
      .content_wrap {
        position:absolute;
        top:0;
        right:0;
        bottom:0;
        left:0;
        background:#000;
        overflow: hidden;
        .info_center {
          padding:100px 50px;
          background:#38383d;
          border-radius:4px;
          width:1000px;
          position:absolute;
          top:50%;
          left:50%;
          -webkit-transform: translate(-50%, -60%);
          transform: translate(-50%, -60%);
          text-align:center;
          line-height: 1.5;
          h2 {
            color:#00adef;
            margin-top:0;
            margin-bottom:20px;
          }
          p {
            color:#d7d7d8;
            margin-top:0;
            margin-bottom:20px;
            .waiting {
              display:inline-block;
              padding:15px 25px;
              color:#919194;
              border:1px solid #919194;
              border-radius:4px;
            }
          }
        }
        .video_wrap {
          position:absolute;
          top:0;
          right:0;
          bottom:0;
          left:0;
          display: flex;
          flex-direction: column;
          .info {
            padding:20px;
            background:#2f3236;
            position:absolute;
            top:0;
            right:0;
            left:0;
            z-index:1;
            h4 {
              margin-top:0;
              margin-bottom:10px;
              color:#fff;
              font-weight: normal;
            }
            p {
              font-size:12px;
              color:#ccc;
              margin:0;
              max-height:50px;
              overflow-x:hidden;
              overflow-y:auto;
              padding:5px 0;
            }
            :deep(.live_select) {
              .el-input__inner {
                width:350px;
                background:transparent;
              }
            }
            .back_btn {
              position:absolute;
              right:20px;
              top:20px;
            }
          }
        }
      }
    }
  }
.layer {
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  z-index:1;
}
.not-select{
  -moz-user-select:none; /*火狐*/
  -webkit-user-select:none; /*webkit浏览器*/
  -ms-user-select:none; /*IE10*/
  -khtml-user-select:none; /*早期浏览器*/
  user-select:none;
}
</style>
