<template>
  <div>
<!--    <el-tooltip-->
<!--        effect="light"-->
<!--        content="课程章节"-->
<!--        placement="right-start"-->
<!--      >-->
    <div class="chapter_btn"
         ref="chapterBtn"
         draggable="false"
         @click.prevent="clickBtnFunc($event)"
         @mousedown.prevent="downBtnFunc($event)"
         :style="{'top': btnY + 'px'}">
      <i class="el-icon-menu"></i>
      <div class="title">课<br>程<br>章<br>节</div>
    </div>
<!--    </el-tooltip>-->

    <el-drawer
      v-model="visible"
      title="课程章节"
      :direction="direction"
    >
      <div class="chapter_list" v-loading="loading">
        <dl>
          <div v-for="(chapter_item, chapter_index) in chapterList">
            <dt v-if="chapterList.length > 1">{{ chapter_item.title }}</dt>
            <dd @click="goPath(item)"
                :class="{'active': item.video_uuid == uuid || item.live_uuid == uuid || item.face_uuid == uuid || item.authentication_uuid == uuid || (item.type == 'experiment' && type == 'experiment' && item.state === '学习中')}"
                v-for="(item, index) in chapter_item.chapter_content">
              <div class="left">
                <i :class="{'iconfont': true, 'icon-qqq-02': item.live_uuid, 'icon-qqq_huaban1': item.face_uuid,
                  'icon-qqq-03': item.video_uuid, 'icon-tubiaocankaoxian1024-05': item.authentication_uuid, 'icon-tubiaocankaoxian1024-04-copy-copy': item.experiment_uuid}"></i>
                <span class="title text_ellipsis">{{item.name}}</span>
              </div>
              <div class="right">
                <span>{{ item.state }}</span>
              </div>
            </dd>
          </div>
        </dl>
      </div>
    </el-drawer>

    <el-dialog
        title="提示"
        v-model="exptModal.visible"
        width="500px" @close="exptModal.visible = false">
      <p class="text-center">
        <i class="el-icon-warning" :style="exptModal.iconStyle"></i>
        <span :style="exptModal.textStyle">您存在课程实验正在运行，确定结束原实验吗？</span>
      </p>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="continue_old_expt()">取消，继续原实验</el-button>
          <el-button @click="create_new_expt()" type="primary">确定，开始新实验</el-button>
        </span>
      </template>

    </el-dialog>

    <!-- 透明遮罩 -->
    <div class="layer" v-if="showLayer"></div>

  </div>
</template>

<script>
import { getCurRecord, getCurChapter } from "api/course/course/study"
import { checkExpt, deleteExpt ,createUserRecord,continueExptRecord } from "api/experiment/list"
import { startExpt } from "api/course/course/create"
export default {
  data() {
    return {
      visible: false,
      direction: 'ltr',
      cur_uuid: '',
      uuid: '',
      type: '',
      chapterList: [],
      del_data:{
        uuid:'',
        name:'',
        expt_url:'',
        course_uuid:'',
        old_cur_uuid:''
      },
      new_data_uuid:'',
      exptModal: {
        visible: false,
        iconStyle: 'font-size:32px;color:#E6A23C;vertical-align: middle;margin-right:10px;',
        textStyle: 'vertical-align: middle;'
      },
      cur_url:'',
      loading:false,
      btnH: 0,
      clientH: 0,
      canMove: false,
      eventY: 0,
      startBtnY: 150,
      btnY: 150,
      showLayer: false,
      canOpenChapter: false
    }
  },
  mounted() {
    this.cur_uuid = this.$route.params.cur_uuid ? this.$route.params.cur_uuid : this.$route.query.cur_uuid
    this.uuid = this.$route.params.uuid
    this.type = this.$route.params.type === 'expt' ? 'experiment' : this.$route.params.type
    this.setChapterState()

    this.cur_url = 'course/detail/' + this.cur_uuid

    this.setHeight()

    document.body.addEventListener('mousemove',this.moveFunc, {passive: false})
    document.body.addEventListener('mouseup',this.upFunc, {passive: false})
    window.addEventListener('resize', this.setHeight, {passive: false})
  },
  unmounted() {
    document.body.removeEventListener('mousemove',this.moveFunc)
    document.body.removeEventListener('mouseup',this.upFunc)
    window.removeEventListener('resize', this.setHeight)
  },
  methods: {
    setHeight() {
      this.btnH = this.$refs.chapterBtn.clientHeight
      this.clientH = document.documentElement.clientHeight || document.body.clientHeight
    },
    clickBtnFunc() {
      if(this.canOpenChapter) {
        this.visible = true
      }
    },
    downBtnFunc(event) {
      this.canOpenChapter = true
      this.canMove = true
      this.startBtnY = event.currentTarget.offsetTop
      this.eventY = event.pageY
    },
    upFunc() {
      if(this.canMove) {
        this.canMove = false
        this.showLayer = false
      }
    },
    moveFunc() {
      if(this.canMove) {
        this.canOpenChapter = false
        this.showLayer = true
        const eventY = event.pageY
        const dis = eventY - this.eventY

        if(this.startBtnY + dis < 0) {
          this.btnY = 0
        } else if(this.startBtnY + dis > this.clientH - this.btnH) {
          this.btnY = this.clientH - this.btnH
        } else {
          this.btnY = this.startBtnY + dis
        }
      }
    },
    setChapterState() {
      let params = {'uuid': this.uuid, 'cur_uuid': this.cur_uuid}
      const that = this
      getCurChapter(params).then(res => {
        if(res.code == 0){
          that.loading = true
          that.chapterList = res.chapter
          let video_index = 0
          let now = new Date()
          for(let i=0; i<this.chapterList.length; i++) {
            this.chapterList[i].chapter_content.forEach(item => {
              if (item.face_uuid) {
                item.start_at = dayjs(item.face_teaching.start_at).format('MM-DD HH:mm')
                item.end_at = dayjs(item.face_teaching.end_at).format('MM-DD HH:mm')
                let position = JSON.parse(item.face_teaching.position)
                if (position[0].label.indexOf('市') >= 0) {
                  item.city = position[0].label
                } else {
                  item.city = position[1].label
                }
                if (now < new Date(item.face_teaching.start_at)) {
                  item.state = '未开始'
                } else if (now < new Date(item.face_teaching.end_at)) {
                  item.state = '进行中'
                } else {
                  item.state = '已结束'
                }
              } else if (item.live_uuid) {
                item.start_at = dayjs(item.live.start_at).format('MM-DD HH:mm')
                item.end_at = dayjs(item.live.end_at).format('MM-DD HH:mm')
                if (now < new Date(item.live.start_at)) {
                  item.state = '未开始'
                } else if (now < new Date(item.live.end_at)) {
                  item.state = '进行中'
                } else {
                  item.state = '已结束'
                }
              } else if (item.video_uuid) {
                item.video_index = video_index++
              }
            })
          }
          this.getStudyState()
        }
      })
    },
    getStudyState() {
      let data = {
        cur_uuid: this.cur_uuid
      }
      const that = this
      getCurRecord(data).then(res => {
        if(res.code === 0){
          let video_obj = {}
          let codes = []
          for(let i=0; i<res.video_records.length; i++){
            let record = res.video_records[i]
            if(record.is_finish || !(record.video_uuid in video_obj)){
              video_obj[record.video_uuid] = record.is_finish
            }
          }
          for(let i=0; i<res.codes.length; i++){
            codes.push(res.codes[i].auth_uuid)
          }
          that.chapterList.forEach(chapter => {
            chapter.chapter_content.forEach(item => {
              if(item.video_uuid){
                if (item['video_uuid'] in video_obj){
                  item.state = video_obj[item['video_uuid']] == 1 ? '已学习' : '学习中'
                }
                else {
                  item.state = '未学习'
                }
              }
              if(item.authentication_uuid){
                if(codes.indexOf(item.authentication_uuid) >= 0){
                  item.state = '已获取'
                }
                else {
                  item.state = '发放中'
                }
              }
              if(item.experiment_uuid){
                  if(item.state == 'running'){
                    item.state = '学习中'
                  }
                  else if (item.state == 'over'){
                    item.state = '已学习'
                  }
                  else if (item.state == ''){
                    item.state = '未学习'
                  }else if (item.state == 'null'){
                      item.state = '暂未发布'
                  }
              }
            })
          })
       }
       that.loading = false
      })
    },
    goPath(item) {
      if (item.type != 'experiment'){
        var record_data = {'curr_uuid':this.cur_uuid,'type':item.type,'uuid':item.uuid}
        createUserRecord(record_data).then(() => {
            this.$router.push(`/course/detail/${item.type}/${this.cur_uuid}/${item.uuid}`);
        })
      }else{
        if (item.state != '暂未发布'){
          this.study_experiment(item.uuid)
        }
      }
    },
    study_experiment(experiment_uuid){
        var check_data = {'course_uuid':experiment_uuid,'cur_uuid':this.cur_uuid}
        checkExpt(check_data).then((result) => {
          if (result.data.code == 0 && result.data.old_expt == false) {
            this.del_data.uuid = result.data.data['uuid']
            this.del_data.name = result.data.data['name']
            this.del_data.expt_url = result.data.data['expt_url']
            this.del_data.course_uuid = result.data.data['course_uuid']
            this.del_data.old_cur_uuid = result.data.old_cur_uuid
            this.new_data_uuid = experiment_uuid

            this.exptModal.visible = true
          } else if(result.data.code == 0 && result.data.old_expt == true){
            var record_data = {'curr_uuid':this.cur_uuid,'expt_uuid':experiment_uuid,'instance_uuid':result.data.data.uuid}
            continueExptRecord(record_data).then(() => {
              this.$router.push(`/expt/detail/${result.data.data.uuid}/?cur_uuid=${this.cur_uuid}&from=/${this.cur_url}`);
            })
          }else{
            this.create_expt(experiment_uuid)
          }

        }).catch(err => {
          this.$alert(`${err}`, '提示', {
            confirmButtonText: '确定'
          });
        });
    },
    continue_old_expt(){
      const that = this
      var record_data = {'curr_uuid':this.del_data.old_cur_uuid,'expt_uuid':this.del_data.course_uuid,'instance_uuid':this.del_data.uuid}
      continueExptRecord(record_data).then(() => {
        this.exptModal.visible = false
        that.$router.push(`/expt/detail/${this.del_data.uuid}/?cur_uuid=${this.del_data.old_cur_uuid}&from=/${this.cur_url}`)
      })
    },
    create_new_expt(){
        //  关闭旧实验开启新实验
        var del_data = {'uuid': this.del_data.uuid,'name':this.del_data.name}
        deleteExpt(del_data).then((result_data) => {
          if (result_data.data.code == 0) {
            this.create_expt(this.new_data_uuid)
          }
        })
    },

    create_expt(uuid){
        //  开始新实验
        var data = {'course_uuid':uuid,'curr_uuid':this.cur_uuid}
        startExpt(data).then((res) => {
          if (res.data.code == 0){
            this.$router.push(`/expt/detail/${res.data.expt_uuid}/?cur_uuid=${this.cur_uuid}&from=/${this.cur_url}`);
          }else{
            this.$alert(`${res.data.err_message}`, '提示', {
              confirmButtonText: '确定'
            });
          }
        }).catch(err=>{
        this.$alert(`${err}`, '提示', {
          confirmButtonText: '确定'
        });
      });
    },
  },
}
</script>

<style lang="scss" scoped>
.chapter_btn {
  position:fixed;
  top:150px;
  left:0;
  padding:10px;
  background-color:#ff6600;
  border-radius: 0 2px 2px 0;
  cursor: pointer;
  z-index:50;
  text-align:center;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10+ and Edge */
  user-select: none; /* Standard syntax */
  i {
    color:#fff;
    font-size:18px;
    margin-bottom:5px;
  }
  .title {
    color: #fff;
    line-height: 1.4;
  }
}
.chapter_list {
  padding:0 20px;
  position:absolute;
  top:77px;
  left:0;
  right:0;
  bottom:0;
  overflow-y: auto;
  overflow-x: hidden;
  dl {
    margin:0;
    dt {
      padding:10px;
      background:#f5f5f5;
      color:#333;
    }
    dd {
      margin:0;
      padding:10px;
      display:flex;
      font-size:12px;
      cursor: pointer;
      .left {
        flex:1;
        display: flex;
        align-items: center;
        overflow:hidden;
        > i {
          color:#9da6b7;
          font-size:18px;
          margin-right:10px;
        }
        > .title {
          flex:1;
          min-width: auto;
          padding-top: 4px;
          padding-bottom: 4px;
        }
      }
      .right {
        width:60px;
        color:#999;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &:hover {
        background:#f2fbff;
      }
      &.active {
        .left {
          > i, > .title {
            color:#009dd9;
          }
        }
      }
    }
  }
}
.layer {
  position:fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  z-index:40;
}
</style>
